<template>
  <div class="details-index">
    <div>
      任务名称：<span>{{ detailsArr[index].name }}</span>
    </div>
    <div>
      任务描述：<span>{{ detailsArr[index].describe }}</span>
    </div>
    <div>
      作业时间：<span>{{ detailsArr[index].time }}</span>
    </div>
    <div>
      作业类型：<span>{{ detailsArr[index].type }}</span>
    </div>
    <div>
      制定班级：<span>{{ detailsArr[index].class }}</span>
    </div>
    <div>素材下载：<el-link :underline="false" type="primary" @click="download">立即下载</el-link></div>
    <el-button style="margin: 150px 0 0 0" @click="goBack">返回</el-button>
  </div>
</template>

<script>
export default {
  name: "Details",
  components: {},
  props: {},
  data() {
    return {
      index: Number(this.$route.query.id),
      detailsArr: [
        { name: "美妆达人秀：化妆技巧分享与交流", describe: "美妆达人分享技巧，成为化妆专家", time: "2023-03-31 ~ 2023-03-31", type: "图文", class: "三年一班", file: "" },
        { name: "智能家居新品发布会：家电科技改变生活", describe: "领略最新智能家居产品，科技改变生活方式", time: "2024-04-11 ~ 2024-04-12", type: "视频", class: "三年一班", file: "" },
        { name: "步步高升：探索鞋靴时尚潮流", describe: "发现最新鞋靴潮流，引领时尚前沿", time: "2024-04-15 ~ 2024-04-30", type: "视频", class: "三年二班", file: "" },
        { name: "家电购物节：狂欢价、新品抢先知", describe: "特惠家电产品，新品抢先知", time: "2024-04-30 ~ 2023-05-31", type: "视频", class: "三年二班", file: "" },
      ],
    };
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.push("/teacher/work/index");
    },
    download() {
      window.open("http://zblive.e-class.me/uploads/system/script.pdf");
    },
  },
};
</script>

<style scoped lang="scss">
.details-index {
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  line-height: 60px;
  > div {
    color: #333;
    > span {
      color: #999;
    }
  }
}
</style>
